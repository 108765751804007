

































import Vue from 'vue';
// import HelloWorld from './components/HelloWorld.vue';

import firebase from 'firebase/app';
import 'firebase/database';
const firebaseConfig = {
    apiKey: 'AIzaSyCoqCxUkedFmB_wdRpHBS3Jg7hgzFGhZTg',
    authDomain: 'scavanger-hunt-4dfd0.firebaseapp.com',
    databaseURL: 'https://scavanger-hunt-4dfd0-default-rtdb.firebaseio.com',
    projectId: 'scavanger-hunt-4dfd0',
    storageBucket: 'scavanger-hunt-4dfd0.appspot.com',
    messagingSenderId: '45351351171',
    appId: '1:45351351171:web:4a6f5495db7a91a3e6bcb6',
};
const app = firebase.initializeApp(firebaseConfig);
const db = app.database();

export default Vue.extend({
    name: 'App',
    components: {
        // HelloWorld,
    },
    data() {
        return {
            teams: [
                {
                    id: '1',
                    name: 'toprope',
                },
                {
                    id: '2',
                    name: 'wilder kaiser',
                },
                {
                    id: '3',
                    name: 'falken',
                },
                {
                    id: '4',
                    name: 'backoffice',
                },
            ],
            selectedTeamId: '',
            currentQuestion: null,
            answer: '',
            error: '',
            loading: false,
            quizOver: false,
        };
    },
    async mounted() {
        // get team from localstore
        const localStoreTeamId = localStorage.getItem('teamId');
        if (localStoreTeamId) {
            this.selectedTeamId = localStoreTeamId;
        } else {
            this.selectedTeamId = '1';
        }

        // load initial states
        const over = await this.getQuizIsDoneOnce();
        if (over) {
            this.quizOver = true;
        } else {
            const currentQuestionInitial = await this.getCurrentQuestionOnce();
            this.currentQuestion = currentQuestionInitial;
        }

        // setup listener
        this.setupCurrentQuestionListener();
        this.setupQuizIsOverListener();
    },
    methods: {
        changeTeam(id: string) {
            this.selectedTeamId = id;
            localStorage.setItem('teamId', id);
        },
        async getCurrentQuestionOnce() {
            const ref = db.ref('quiz/0001');
            const intref = ref.child('currentQuestion');
            const snapshot = await intref.once('value');
            return snapshot.val();
        },
        setupCurrentQuestionListener() {
            const ref = db.ref('quiz/0001');
            const intref = ref.child('currentQuestion');
            intref.on('value', snapshot => {
                const val = snapshot.val();
                this.currentQuestion = val;
                this.error = '';
            });
        },
        async getQuizIsDoneOnce() {
            const ref = db.ref('quiz/0001');
            const intref = ref.child('quizIsDone');
            const snapshot = await intref.once('value');
            return snapshot.val();
        },
        setupQuizIsOverListener() {
            const ref = db.ref('quiz/0001');
            const intref = ref.child('quizIsDone');
            intref.on('value', snapshot => {
                const val = snapshot.val();
                if (val === true) {
                    this.quizOver = true;
                    this.currentQuestion = null;
                    this.error = '';
                }
            });
        },
        async submitAnswer() {
            if (!this.answer) {
                this.error = 'The answer is not nothing. I promise.';
                return;
            }
            try {
                this.error = '';
                this.loading = true;
                const host = process.env.VUE_APP_BASE_URL || 'http://localhost:3000';
                const url = `${host}/question/current/submit`;
                const data = JSON.stringify({
                    teamId: '' + this.selectedTeamId,
                    answer: this.answer,
                });
                const requestConfig: RequestInit = {
                    headers: { 'Content-Type': 'application/json' },
                    body: data,
                    method: 'POST',
                };
                const response = await fetch(url, requestConfig);
                // const serverAnswer = await response.json();
                switch (response.status) {
                    case 404:
                        this.error = 'quiz is over.';
                        break;
                    case 406:
                        this.error = 'answer is incorrect';
                        break;
                    case 200:
                        break;
                    default:
                        this.error = 'unexpected error';
                        break;
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
                this.answer = '';
            }
        },
    },
});
